import $ from "cash-dom";
import { retriveUrl } from "./axios.js";

function mark_readed(message) {
    if (!message.read) {
        let chatSoket = window.API.getUserSocket();
        let new_message = {
            'type': "chat.read",
            'id': message.id,
            'sender': message.sender,
            'receiver': message.receiver
        };
        console.log(new_message);
        chatSoket.send(JSON.stringify(new_message));
        $("#chat_" + message.sender + " .message-received").hide();
        const visible_messages = $(".message-received").filter(function(index, element){
            return $(element).css("display") != "none";
        });
        if(visible_messages.length == 1){
            $(".message-received").hide();
        }
    }
}

function messaggioChatAperta(message, time) {
    // Formats and append message to chat
    const formatted_time = time.toLocaleString($("html").attr("lang"));
    let classSender = "meSide";
    let classReaded = "unreaded";
    if (message.receiver == $("body").data("user")) {
        classSender = "senderSide";
        mark_readed(message);
    }
    if (message.read) {
        classReaded = "readed";
    }
    $('#chat').append('<div class="uk-width-1-2 uk-margin-small talk-bubble ' + classSender + '" id="chat_message_' + message.id + '"><p class="uk-text-xsmall uk-margin-remove-bottom uk-margin-small-top">'+formatted_time+'<span uk-icon="icon:check"class="'+classReaded+'"></span></p>'+message.text+'</div>');
    $('.talk-bubble').last()[0].scrollIntoView(false);
    $('#chat-message-input')[0].scrollIntoView(false);
}

function messaggioChatChiusa(message) {

    const sender = message.sender;
    if (message.receiver == $("body").data("user")) {
        let count_shakes = 2;
        const shake_interval = setInterval(shake, 1500);
        function shake(){
            if (count_shakes == 0){
                clearInterval(shake_interval);
            }
            else{
                $("#chat-received").addClass("uk-animation-shake");
                setTimeout(function(){
                    $("#chat-received").removeClass("uk-animation-shake");
                }, 1000);
                count_shakes--;
            }
        }
        $("#chat-received > .message-received").show();
        $("#chat_" + sender + " .message-received").show();
        $("#message-refresh").show();
    }
}

function initChat(chatMessages) {
    const sender = $('#chat').data("sender");
    const receiver = $('#chat').data("receiver");
    const chatSocket = window.API.getUserSocket();
    $.each(chatMessages, function (index, message) {
        const date = new Date(message.time);
        messaggioChatAperta(message, date);
    });

    $("#chat-message-submit").on("click", function(evt){
        evt.preventDefault();
        const message_input = $("#chat-message-input");
        if (message_input.val() == '') {
            return;
        }
        const message = {
            'type': "chat.message",
            'text': message_input.val(),
            'time': new Date(),
            'sender': sender,
            'receiver': receiver
        };
        console.log(message);
        chatSocket.send(JSON.stringify(message));
        message_input.val('');
    });
    $("#chat-form").on("submit", function(evt){
        evt.preventDefault();
        $('#chat-message-submit').click()
    });

    retriveUrl("/user/profile/" + receiver + "/info.json").then(response => {
        $('#avatar-receiver').html(response.data.avatar)
    });
    retriveUrl("/user/profile/" + sender + "/info.json").then(response => {
        $('#avatar-sender').html(response.data.avatar);
    });
}

export { initChat, messaggioChatAperta, messaggioChatChiusa };
