// client.js
import axios from 'axios';

const baseUrl = window.location.protocol + "//" + window.location.host;

const axiosClient = axios.create({
    baseURL: baseUrl,
    /* other custom settings */
});


async function postData(path, data={}){
    let form_data = new FormData();
    for(const key in data){
      form_data.append(key, data[key]);
    }
    const header = {"Content-Type": "application/x-www-form-urlencoded"};
    try {
        const response = await axiosClient({method: "POST", url: path, data: form_data, header: header});
        return response
    }
    catch (err) {
        console.error(err);
        throw err;
    }
}


async function retriveUrl(path, method="get", data={}) {
  try {
      const response = await axiosClient({method: method, url: path, data: data});
      return response
  }
  catch (err) {
      console.error(err);
      throw err;
  }
}

export {retriveUrl, postData};