import UIkit from 'uikit';
import $ from "cash-dom";
import { retriveUrl, postData } from "./axios.js";
import { getCookie } from './cookies.js';
import { initChat, messaggioChatAperta, messaggioChatChiusa } from "./chat.js";

function init_form_errors() {
    $("#form-errors .errorlist li").each(function () {
        UIkit.notification({ message: $(this).text(), status: "danger" });
    });
}

function htmlReplace(data){
    const url = data["url"] || ".*";
    if (window.location.href.match(url)){
        const html = data["html"] || "";
        const target = data["target"] || "body";
        $(target).html(html);
        initFormErrors(data["target"]);
    }
}

function pageRedirect(data){
    const from_url = data["from_url"] || ".*";
    if (window.location.href.match(from_url)){
        window.location.href = data["to_url"];
    }
}

function getUserSocket() {
    // Gets or creates the user socket
    if (window.API.websocket) {
        if (window.API.websocket.readyState === WebSocket.OPEN) {
            return window.API.websocket
        }
    }
    console.log('Creating new socket for user');
    const protocol = window.location.protocol.replace('http', 'ws');
    const seed = Math.random().toString(36).substring(2, 15);
    let ws = new WebSocket(protocol + '//' + window.location.host + '/ws/user/' + seed + '/');

    ws.onmessage = function (e) {
        const message = JSON.parse(e.data);
        const room = $('#chat').data("room");
        console.log("message received");
        console.log(message);
        if (message.type == "chat.message") {
            if ($('#chat').length > 0 && room == message.room) {
                const time = new Date(message.time);
                messaggioChatAperta(message, time);
            }
            else {
                messaggioChatChiusa(message);
            }
        }
        if (message.type == "chat.read") {
            $("#chat_message_" + message.id + " .unreaded").removeClass("unreaded").addClass("readed");
        }
        else if (message.type == "page.redirect"){
            pageRedirect(message);
        }
        else if (message.type == "html.replace") {
            htmlReplace(message);
        }
    };

    ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function () {
            getUserSocket();
        }, 3000);
    };

    ws.onerror = function (e) {
        console.error('Socket encountered error: ', e.message, 'Closing socket');
        ws.close();
    };
    window.API.websocket = ws;
    return ws;
}

function openModal(path, large=false, callback=false) {
    let selector = "#modal-container";
    if(large){
        selector = "#modal-container-large";
    }
    retriveUrl(path).then(response => {
        $(selector).html(response.data);
        if(callback){
            callback();
        }
        init_form_errors();
    }).catch(function (error) {
        console.log("Error fetching modal from url " + path);
    });
    const modal = UIkit.modal(selector, { center: true });
    modal.show();
}

function trackAnalyticsEvent(category, action, label) {
    _paq.push(['trackEvent', action, category, label]);
}

function openPage(path, large=false, callback=false) {
    trackAnalyticsEvent('page', 'click', path);
    openModal(path, large, callback);
}

function openEvent(path) {
    trackAnalyticsEvent('event', 'click', path);
    openModal(path);
}
    
function openVideo(path) {
    trackAnalyticsEvent('video', 'click', path);
    openModal(path);
}

function openAudio(path) {
    trackAnalyticsEvent('audio', 'click', path);
    openModal(path);
}

function openPdf(path) {
    trackAnalyticsEvent('pdf', 'click', path);
    openModal(path);
}

function saveAvatar(object) {
    const url = $('#unityContainer').data("avatar-url");
    const data = { avatar: object, csrfmiddlewaretoken: getCookie('csrftoken') };
    postData(url, data).then(function (response) {
        window.unityInstance.SendMessage("Networking", "SetAvatar", JSON.stringify(response.data));
    });
}

function getAvatar() {
    const url = $('#unityContainer').data("avatar-url");
    retriveUrl(url).then(function (response) {
        window.unityInstance.SendMessage("Networking", "GetAvatar", JSON.stringify(response.data));
    });
}
function openChat(path) {
    trackAnalyticsEvent('chat', 'click', path);
    openModal(path, true, function(){
        initChat(JSON.parse($("#chat-messages").text()))
    });
}


function downloadFile(path){
    trackAnalyticsEvent('download', 'click', path);
    window.location.replace(path);
}


window.API = {
    getUserSocket: getUserSocket,
    downloadFile: downloadFile,
    openModal: openModal,
    openChat: openChat,
    trackAnalyticsEvent: trackAnalyticsEvent,
    openVideo: openVideo,
    openAudio, openAudio,
    openEvent: openEvent,
    openPage: openPage,
    openPdf: openPdf,
    saveAvatar: saveAvatar,
    getAvatar: getAvatar
}
