function getCookie(name) {
    name += "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    var cookies = decodedCookie.split(';');
    for(let i = 0; i <cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}

  function setCookie(name, value, expireindays) {
    var date = new Date();
    date.setTime(date.getTime() + (expireindays*24*60*60*1000));
    var expires = "expires="+ date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

export {getCookie, setCookie}